// @flow
import React, {FunctionComponent, useEffect, useState} from 'react';
import {CreateAnimalDto, JsonAnimal} from "../api/generated/rest-dto";
import {AnimalService} from "../services/animal-service";
import {useLocation} from "react-router-dom";
import RouteService from "../services/route-service";
import AnimalBaseDataForm from "../components/AnimalCreate/animal-base-data-form";
import {PregnancyListJson} from "../api/generated/medical-rest";
import {AnimalBaseData, transformToCreateAnimalDto} from "../api/dtos/animal-base-data";
import {AnimalController$CreateAnimalResult} from "../api/generated/animal-controller";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";
import TabularTextView from "../components/AnimalDashboard/Common/tabular-text-view";
import {transformFromJsonAnimal} from "../util/animal-util";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {OwnershipService} from "../services/ownership-service";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import {useNav} from "../components/Common/hooks/use-nav";

export interface AnimalCreateLocationState {
    pregnancy?: PregnancyListJson
}

interface ExternallyOwnedDialogProps {
    open: boolean;
    onSuccess: (panonId: string) => void;
    onClose: () => void;
    animals: JsonAnimal[];
}

const ExternallyOwnedDialog: FunctionComponent<ExternallyOwnedDialogProps> = ({ open, onClose, onSuccess, animals }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { success, error } = useGlobalSnackbarStore();
    const queryClient = useQueryClient();

    const ownershipClaimMutation = useMutation({
        mutationKey: ["ownershipClaim"],
        mutationFn: async (animal: JsonAnimal) => {
            await OwnershipService.claimOwnership(animal.panonIdentifier);
            return animal;
        },
        onError: (err) => {
            error("Fehler beim Übertragen des Besitzes: " + err);
        },
        onSuccess: (animal: JsonAnimal) => {
            onSuccess(animal.panonIdentifier.id);
            success("Besitz erfolgreich übertragen!");
            queryClient.invalidateQueries({queryKey: ["herd"]});
        },
        onSettled: onClose
    });

    return (
        <Dialog open={open} onClose={onClose} fullScreen={isMobile} maxWidth="sm">
            <DialogTitle>Existierende Tiere mit dieser Chipnummer</DialogTitle>
            <DialogContent>
                {animals.map((animal, index) => (
                    <div key={animal.id} style={{ marginBottom: "1rem" }}>
                        <TabularTextView displayPairs={transformFromJsonAnimal(t, animal)} />
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginTop: "0.5rem" }}
                            onClick={() => ownershipClaimMutation.mutate(animal)}
                        >
                            Besitz auf mich übertragen
                        </Button>
                        {index < animals.length - 1 && <Divider style={{ margin: "1rem 0" }} />}
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">Kein Tier stimmt überein</Button>
            </DialogActions>
        </Dialog>
    );
};

const AnimalCreate: FunctionComponent = () => {
    const {state: {pregnancy}} = useLocation<AnimalCreateLocationState>();
    const queryClient = useQueryClient();
    const { success, error } = useGlobalSnackbarStore();
    const nav = useNav();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [chipNumber, setChipNumber] = useState("");

    const createAnimalMutation = useMutation({
        mutationKey: ["createAnimal"],
        mutationFn: async (animal: AnimalBaseData) => {
            const createAnimalDto: CreateAnimalDto = transformToCreateAnimalDto(animal, pregnancy?.id);
            return await AnimalService.createForHerd(createAnimalDto);
        },
        onError: (err) => {
            error("Fehler beim Erstellen des Tieres: " + err);
        },
        onSuccess: (createdAnimal: AnimalController$CreateAnimalResult) => {
            if (createdAnimal.info === "created") {
                success("Tier erfolgreich angelegt!", RouteService.expand(RouteService.ANIMAL_CHANGE_ANCESTRY, {panonId: createdAnimal.panonIdentifier.id}));
                queryClient.invalidateQueries({ queryKey: ["herd"] });
            } else {
                error("Fehler beim Anlegen des Tieres.");
            }
        }
    });

    const {data} = useQuery({
        queryKey: ['checkOwnership', chipNumber],
        queryFn: async () => {
            if (!chipNumber) return [];
            try {
                return await OwnershipService.findExternallyOwnedByChipId(chipNumber);
            } catch (error) {
                return []; // do not disallow creation only because of a quality of life functionality
            }
        }
    });


    useEffect(() => {
        if(data && data.length > 0){
            setDialogOpen(true)
        }
    }, [data]);


    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <AnimalBaseDataForm
                onSubmitClicked={createAnimalMutation.mutate}
                pregnancy={pregnancy}
                onChipNumberBlur={setChipNumber}
            />

            <ExternallyOwnedDialog
                open={dialogOpen}
                onSuccess={(panonId) => nav.push(RouteService.ANIMAL_DETAILS, {panonId})}
                onClose={handleDialogClose}
                animals={data ?? []}
            />
        </div>
    );
};

export default AnimalCreate
