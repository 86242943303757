import {ApiConfigPort} from '../config/api-config-port';
import {AlpacaBreed, AlpacaFiberColor, PanonIdentifier, Sex} from './herd-animal';
import {RegistryId} from './herds-registry';
import {HttpService} from '../../services/http-service';
import {CreateResult, ListResult, NamedId, Result, SingleResult, UpdateResult} from './lib-rest';
import {RegistryDto, RegistryDto$BreederRef} from './registry-service';

export interface AssignRegistryAdminCommand {
  userId: string;
}

export class BreederRegistrationsApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async registeredAnimals(ownerId: string): Promise<ListResult<BreederRegistrationsController$JsonRegistration> >  {
const url =  new URL('/breeders/' + ownerId + '/animals/registered', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

}

export interface BreederRegistrationsController$JsonRegistration {
  breed: AlpacaBreed;
  dateOfBirth: any;
  fiberColor: AlpacaFiberColor;
  herdCode: string;
  name: string;
  panonId: PanonIdentifier;
  registryId: RegistryId;
  registryName: string;
  sex: Sex;
}

export interface CreateRegistryCommand {
  name: string;
}

export class InviteApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async invite(registryId: string, invitedBreederId: string): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/invite', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'breeder', value: invitedBreederId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

}

export interface RegistrationJson {
  herdCode: string;
  id: string;
  name: string;
  panonIdentifier: string;
  phenotype: RegistrationJson$PhenotypeJson;
  registryId: string;
}

export interface RegistrationJson$PhenotypeJson {
  breed: AlpacaBreed;
  dateOfBirth: any;
  eyeColor: string;
  primaryColor: AlpacaFiberColor;
  secondaryColors: AlpacaFiberColor[];
  sex: Sex;
}

export class RegistrationRequestApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async get(requestId: string): Promise<SingleResult<RegistrationRequestController$PendingRequestJson> >  {
const url =  new URL('/registries/pendingRequests/' + requestId + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async list(registryId?: string, breederId?: string, panonIdentifier?: string, includeRejected?: boolean | null): Promise<ListResult<RegistrationRequestController$PendingRequestJson> >  {
const url =  new URL('/registries/pendingRequests', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    if (registryId !== undefined && registryId !== null) {
      queryParamsList.push({name: 'registryId', value: registryId});
    }

    if (breederId !== undefined && breederId !== null) {
      queryParamsList.push({name: 'breederId', value: breederId});
    }

    if (panonIdentifier !== undefined && panonIdentifier !== null) {
      queryParamsList.push({name: 'panonIdentifier', value: panonIdentifier});
    }

    if (includeRejected !== undefined && includeRejected !== null) {
      queryParamsList.push({name: 'includeRejected', value: includeRejected.toString()});
    }

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async reject(requestId: string, comment: string): Promise<Response>  {
const url =  new URL('/registries/pendingRequests/' + requestId + '/reject', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(comment))).data
  }

}

export interface RegistrationRequestController$PendingRequestJson {
  animal: NamedId;
  breeder: NamedId;
  id: string;
  registry: NamedId;
  rejectionComment: string;
  rejectionTime: any;
}

export class RegistrationsApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async details(panonId: string, registryId: string): Promise<SingleResult<RegistrationJson> >  {
const url =  new URL('/registry/animals/' + panonId + '/registrations/' + registryId + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async edit(panonId: string, registryId: string, edit: RegistrationsController$RegistrationEditJson): Promise<UpdateResult>  {
const url =  new URL('/registry/animals/' + panonId + '/registrations/' + registryId + '', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(edit))).data
  }

 public  async findByName(name: string): Promise<ListResult<RegistrationJson> >  {
const url =  new URL('/registry/animals/_search', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'name', value: name});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async listRegistrations(panonId: string): Promise<ListResult<RegistrationJson> >  {
const url =  new URL('/registry/animals/' + panonId + '/registrations', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

}

export interface RegistrationsController$RegistrationEditJson {
  breed: AlpacaBreed;
  dateOfBirth: any;
  eyeColor: string;
  herdCode: string;
  name: string;
  primaryColor: AlpacaFiberColor;
  secondaryColors: AlpacaFiberColor[];
  sex: Sex;
}

export class RegistryApi {
  config: ApiConfigPort;


 public  async addMember(registryId: string, command: AssignRegistryAdminCommand): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/members', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(command))).data
  }

 public  async assignAdmin(registryId: string, command: AssignRegistryAdminCommand): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/admins', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(command))).data
  }

 public  async assignWarden(registryId: string, command: AssignRegistryAdminCommand): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/wardens', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(command))).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async details(id: string): Promise<SingleResult<RegistryDto> >  {
const url =  new URL('/registries/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async listMembers(registryId: string): Promise<ListResult<RegistryDto$BreederRef> >  {
const url =  new URL('/registries/' + registryId + '/members', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async newRegistry(command: CreateRegistryCommand): Promise<CreateResult>  {
const url =  new URL('/registries', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(command))).data
  }

 public  async registrations(id: string): Promise<ListResult<RegistrationJson> >  {
const url =  new URL('/registries/' + id + '/registrations', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async removeMember(registryId: string, memberId: string): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/members/' + memberId + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async searchRegistrations(id: string, name: string): Promise<ListResult<RegistrationJson> >  {
const url =  new URL('/registries/' + id + '/registrations/_search', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'name', value: name});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async unassignAdmin(registryId: string, adminId: string): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/admins/' + adminId + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async unassignWarden(registryId: string, wardenId: string): Promise<Result>  {
const url =  new URL('/registries/' + registryId + '/wardens/' + wardenId + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

}

export class RegistryListApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async getBulkByIds(registryIds: string[]): Promise<ListResult<RegistryDto> >  {
const url =  new URL('/registries/bulk', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(registryIds))).data
  }

 public  async list(name?: string, memberId?: string, wardenId?: string, adminId?: string): Promise<ListResult<RegistryDto> >  {
const url =  new URL('/registries', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    if (name !== undefined && name !== null) {
      queryParamsList.push({name: 'name', value: name});
    }

    if (memberId !== undefined && memberId !== null) {
      queryParamsList.push({name: 'memberId', value: memberId});
    }

    if (wardenId !== undefined && wardenId !== null) {
      queryParamsList.push({name: 'wardenId', value: wardenId});
    }

    if (adminId !== undefined && adminId !== null) {
      queryParamsList.push({name: 'adminId', value: adminId});
    }

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

}

export class RequestRegistrationApi {
  config: ApiConfigPort;


 public  async acceptRequest(requestId: string): Promise<CreateResult>  {
const url =  new URL('/registries/{registryId}/accept', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'requestId', value: requestId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async findSimilarAnimals(requestId: string): Promise<ListResult<RequestRegistrationController$AnimalSimilarityJson> >  {
const url =  new URL('/registries/{registryId}/similar', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'requestId', value: requestId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async submitRequest(registryId: string, panonIdentifier: string): Promise<CreateResult>  {
const url =  new URL('/registries/' + registryId + '/submitRequest', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'panonIdentifier', value: panonIdentifier});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

}

export interface RequestRegistrationController$AnimalSimilarityJson {
  panonIdentifier: string;
  score: number;
}

