import {OwnershipApi} from "../api/generated/animal-controller";
import ApiConfig from "../api/config/api-config";
import {JsonAnimal, JsonTransferExternalOwnership, JsonTransferOwnership} from "../api/generated/rest-dto";
import {PanonIdentifier} from "../api/generated/herd-animal";

let ownershipApi = new OwnershipApi(new ApiConfig());

export interface IOwnershipService {

    transferOwnership(newOwnership: JsonTransferOwnership): Promise<void>

    transferOwnershipToExternal(externalOwnership: JsonTransferExternalOwnership): Promise<void>

    findExternallyOwnedByChipId(chipId: string): Promise<JsonAnimal[]>

    claimOwnership(panonId: PanonIdentifier): Promise<void>
}

class OwnershipServiceImpl implements IOwnershipService {
    private _ownershipApi: OwnershipApi;

    constructor(ownershipApi: OwnershipApi) {
        this._ownershipApi = ownershipApi;
    }

    async findExternallyOwnedByChipId(chipId: string): Promise<JsonAnimal[]> {
        return (await this._ownershipApi.getExternallyOwnedByChip(chipId)).items;
    }

    async transferOwnership(newOwnership: JsonTransferOwnership): Promise<void> {
        await this._ownershipApi.transferOwnership(newOwnership)
    }

    async transferOwnershipToExternal(externalOwnership: JsonTransferExternalOwnership): Promise<void> {
        await this._ownershipApi.transferOwnershipToExternal(externalOwnership);
    }

    async claimOwnership(panonId: PanonIdentifier): Promise<void> {
        await this._ownershipApi.claimOwnership(panonId);
    }

}

export const OwnershipService: IOwnershipService = new OwnershipServiceImpl(ownershipApi);
